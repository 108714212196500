<script setup lang="ts">
import { useDisplay } from "vuetify";
import { useConfigStore } from '@core/stores/config'
import type { ThemeSwitcherTheme } from '@layouts/types'
const { mdAndUp } = useDisplay();
const emit = defineEmits(['close'])

const props = defineProps<{
  themes: ThemeSwitcherTheme[]
}>()

const configStore = useConfigStore()

const selectedItem = ref([configStore.theme])

// Update icon if theme is changed from other sources
watch(
  () => configStore.theme,
  () => {
    selectedItem.value = [configStore.theme]
  },
  { deep: true },
)
</script>

<template>
  <IconBtn v-if="mdAndUp" color="rgba(var(--v-theme-on-surface), var(--v-high-emphasis-opacity))">
    <VIcon
      :icon="props.themes.find(t => t.name === configStore.theme)?.icon"
      size="24"
    />

    <VTooltip
      activator="parent"
      open-delay="1000"
      scroll-strategy="close"
    >
      <span class="text-capitalize">{{ configStore.theme }}</span>
    </VTooltip>

    <VMenu
      activator="parent"
      offset="12px"
      :width="180"
    >
      <VList
        v-model:selected="selectedItem"
        mandatory
      >
        <VListItem
          v-for="{ name, icon } in props.themes"
          :key="name"
          :value="name"
          :prepend-icon="icon"
          color="primary"
          class="text-capitalize"
          @click="() => { configStore.theme = name }"
        >
          {{ name }}
        </VListItem>
      </VList>
    </VMenu>
  </IconBtn>

  <div v-else class="megamenu-margin">
    <div 
      class="ma-3 d-flex justify-end"
      v-if="!mdAndUp"
    >
      <div class="d-flex align-center" @click="emit('close')">
        <VIcon size="24" icon="custom-back"/>
      </div>
    </div>
    <VRow>
      <VCol class="d-flex justify-center">
        <div class="mx-md-6">
          <div class="d-flex flex-column mt-1 gap-1">
            <div
              v-for="{ name, icon } in props.themes"  
              @click="() => { configStore.theme = name }"
              class="text-capitalize"
            >
            <VIcon
              :icon="icon"
              size="24"
              class="mr-2"
            />
              {{ name }}
            </div>
          </div>
        </div>
      </VCol>
    </VRow>
  </div>
</template>
